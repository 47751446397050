import React, {useEffect, useState} from 'react'
import './Home.css';
import '../Core.css';
import MyDataHelps, {DeviceDataPointQuery, DeviceInfo, ExternalAccount, Guid, ParticipantInfo, SurveyAnswer, SurveyTask} from '@careevolution/mydatahelps-js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronRight, faCog, faPlusCircle, faRefresh, faAddressCard} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import LocalizedString from "../component/LocalizedString";
import language from "../helpers/Language"
import testResultAssembler from "../helpers/TestResultAssembler";
import {TestResult} from "../types/TestResult";
import TestResultList from "../component/TestResultList";
import {Action} from "@careevolution/mydatahelps-ui";
import {convertToSymptomSharkParticipantInfo, SymptomSharkParticipantInfo} from "@careevolution/symptom-shark";
import SymptomLogSync from "../component/SymptomLogSync";
import Achievementsv3 from '../component/Achievementsv3';
import Achievementsv3_2 from '../component/Achievementsv3_2';

const debounce = require('lodash.debounce');
const moment = require('moment');

export class Cta {
    mode?: string;
    confirmed?: boolean;
    result?: string;
}

export default function () {
    const [loading, setLoading] = useState<boolean>(true);

    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
    const [testResults, setTestResults] = useState<TestResult[]>();
    const [tasks, setTasks] = useState<SurveyTask[]>();
    const [connectedFitbitAccount, setConnectedFitbitAccount] = useState<ExternalAccount>();
    const [hasStartedConsent, setHasStartedConsent] = useState<boolean>();
    const [hasSetupSymptomLog, setHasSetupSymptomLog] = useState<boolean>();

    const loadHasSetupSymptomLog = (): Promise<void> => {
        return MyDataHelps.querySurveyAnswers({surveyName: 'SetupSymptomTracker'}).then(function (response) {
            setHasSetupSymptomLog(response.surveyAnswers.length > 0);
        });
    };

    const saveHasStartedConsent = (): Promise<void> => {
        return MyDataHelps.persistDeviceData([{type: "StartedConsent", identifier: "StartedConsent", value: "true"}]);
    };

    const loadHasStartedConsent = (): Promise<boolean> => {
        let queryParameters: DeviceDataPointQuery = {namespace: "Project", type: "StartedConsent"};
        return MyDataHelps.queryDeviceData(queryParameters).then(function (page) {
            let hasStartedConsent = page.deviceDataPoints.length > 0 && JSON.parse(page.deviceDataPoints[0].value);
            setHasStartedConsent(hasStartedConsent);
            return hasStartedConsent;
        });
    };

    const loadConnectedFitbitAccount = (): Promise<ExternalAccount | undefined> => {
        return MyDataHelps.getExternalAccounts().then(function (externalAccounts) {
            let connectedFitbitAccount: ExternalAccount | undefined;
            for (let i = 0; i < externalAccounts.length; i++) {
                if (externalAccounts[i].provider.name.includes("Fitbit") && !["Unauthorized", "Error"].includes(externalAccounts[i].status)) {
                    connectedFitbitAccount = externalAccounts[i];
                    break;
                }
            }
            setConnectedFitbitAccount(connectedFitbitAccount);
            return connectedFitbitAccount;
        });
    };

    const loadTasks = (): Promise<SurveyTask[]> => {
        return MyDataHelps.querySurveyTasks({status: 'incomplete'}).then(function (tasksPage) {
            let tasks = tasksPage.surveyTasks;
            setTasks(tasks);
            return tasks;
        });
    };

    const loadTestResults = (participantInfo: ParticipantInfo): Promise<TestResult[]> => {
        let answers: SurveyAnswer[] = [];
        let getSurveyAnswersPage = function (pageID?: Guid): Promise<TestResult[]> {
            return MyDataHelps.querySurveyAnswers({
                stepIdentifier: ["Condensed_AddTestStrip", "Full_AddTestStrip", "TestResult", "SelfInterpret"],
                pageID: pageID
            }).then(function (response) {
                answers = answers.concat(response.surveyAnswers);
                if (response.nextPageID) {
                    return getSurveyAnswersPage(response.nextPageID);
                } else {
                    return testResultAssembler.assembleTestResults(participantInfo, answers);
                }
            });
        };

        return getSurveyAnswersPage().then(function (testResults) {
            setTestResults(testResults);
            return testResults;
        });
    };

    const loadParticipantInfo = (): Promise<ParticipantInfo> => {
        return MyDataHelps.getParticipantInfo().then(function (participantInfo) {
            setParticipantInfo(participantInfo);
            return participantInfo;
        });
    };

    const loadDeviceInfo = (): Promise<DeviceInfo> => {
        return MyDataHelps.getDeviceInfo().then(function (deviceInfo) {
            setDeviceInfo(deviceInfo);
            return deviceInfo;
        });
    };

    const updateParticipantIdentifier = (): Promise<void> => {
        let endpoint = 'STOPCOVID19.UpdateParticipantIdentifier';
        return MyDataHelps.invokeCustomApi(endpoint, 'POST', '', false);
    };

    const initialize = () => {
        setLoading(true);
        updateParticipantIdentifier().then(() => {
            loadDeviceInfo().then(() => {
                loadParticipantInfo().then((participantInfo: ParticipantInfo) => {
                    loadTestResults(participantInfo).then(() => {
                        loadTasks().then(() => {
                            loadConnectedFitbitAccount().then(() => {
                                loadHasStartedConsent().then(() => {
                                    loadHasSetupSymptomLog().then(() => {
                                        setLoading(false);
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    };

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    if (loading) {
        return (
            <div className="home">
                <div className="home-header-wrapper">
                    <div className="loading-indicator">
                        <FontAwesomeIcon icon={faRefresh} spin={true}/>
                    </div>
                </div>
                <div className="home-footer">
                    <div className="home-footer-credits"><LocalizedString stringName="footer-credits"/></div>
                    <div className="home-footer-company"><LocalizedString stringName="footer-company"/></div>
                </div>
            </div>
        )
    }

    const startConsent = () => {
        MyDataHelps.startSurvey('Consent');
    };

    const recordResult = () => {
        startSurvey('TestResultAdd');
    };

    const startPcrTest = () => {
        startSurvey('PCRInstructions');
    };

    const startLuciraTest = () => {
        startSurvey('LuciraTestResultAdd');
    };

    const startAntigenTest = () => {
        startSurvey('TestResultAdd');
    };

    const startSurvey = (surveyName: string) => {
        MyDataHelps.startSurvey(surveyName);
    };

    const connectFitbit = () => {
        MyDataHelps.connectExternalAccount(564);
    };

    const logSymptomsAndExposure = () => {
        startSurvey('SymptomsAndExposure');
    };

    const showTestResults = () => {
        MyDataHelps.openApplication(new URL("../test_results", document.location as any).href);
    };

    const onAchievementsClicked = (): void => {
        MyDataHelps.openApplication(new URL('achievements', document.location as any).href);
    };


    const editSymptoms = () => {
        MyDataHelps.startSurvey("UpdateSymptoms");
    };

    const editTreatments = () => {
        MyDataHelps.startSurvey("UpdateTreatments");
    };

    const updateAddress = () => {
        MyDataHelps.startSurvey("UpdateAddress");
    };

    let showAppDownload = !deviceInfo || deviceInfo.platform === 'Web';

    let preferredStatus = participantInfo?.customFields['PreferredStatus'];
    let consentStatus = participantInfo?.customFields['ConsentStatus'];
    let riskGroup = participantInfo?.customFields['RiskGroup'];
    let studyComplete = participantInfo?.customFields['StudyComplete'];
    let consentDate = participantInfo?.customFields['ConsentDate'];
    let studyPhase = participantInfo?.customFields['StudyPhase'];
    let acuteInfectionStartDate = participantInfo?.customFields['AcuteInfectionStartDate'];

    let studyWeek = moment().diff(moment(consentDate), 'weeks') + 1;

    let acuteInfectionDay = 0;
    if (acuteInfectionStartDate) {
        acuteInfectionDay = moment().startOf('day').diff(moment(acuteInfectionStartDate).startOf('day'), 'days');
    }

    let hasIncompletePcrTask = !!tasks!.find(t => t.surveyName === 'PCRInstructions');
    let hasIncompleteLuciraTask = !!tasks!.find(t => t.surveyName === 'LuciraTestResultAdd');

    let cta: Cta = {};
    if (preferredStatus === 'EligibleButNotPreferred') {
        cta.mode = 'eligibleButNotPreferred';
    } else if (preferredStatus === 'Preferred' && consentStatus === '') {
        cta.mode = 'consentIncomplete';
        if (!hasStartedConsent) {
            saveHasStartedConsent().then(function () {
                startConsent();
            });
        }
    } else if (studyComplete) {
        cta.mode = 'studyComplete';
    } else {
        if (studyPhase === 'PreTesting' || (studyPhase === 'AcuteInfection' && acuteInfectionDay < 6) || (studyPhase === 'AcuteInfection' && acuteInfectionDay >= 6 && !(hasIncompleteLuciraTask || hasIncompletePcrTask) )) {
            cta.mode = 'noTestingRequired';
        } else if (studyPhase === 'Testing' || (studyPhase === 'AcuteInfection' && acuteInfectionDay >= 6 && (hasIncompleteLuciraTask || hasIncompletePcrTask))) {
            if (!testResults || testResults.length === 0) {
                cta.mode = 'startFirstTest';
            } else {
                cta.mode = 'startNextTest';
                let lastTest = testResults[testResults.length - 1];
                if (lastTest.result && moment(lastTest.testStripAddTime).isSame(moment(), 'day')) {
                    cta.mode = 'viewResult';
                    cta.result = lastTest.result;
                    cta.confirmed = lastTest.confirmed;
                }
            }
        } else {
            cta.mode = 'none';
        }
    }

    let symptomSharkParticipantInfo: SymptomSharkParticipantInfo = convertToSymptomSharkParticipantInfo(participantInfo!);

    return (
        <div className="home">
            <div className="home-header-wrapper">
                <div>
                    <div className="home-logo"><img src="../images/stop-covid-logo.svg" alt="stop covid logo"/></div>

                    {cta.mode === 'eligibleButNotPreferred' &&
                    <div className="cta">
                        <h1 className="cta-title"><LocalizedString stringName="waitlisted-h1"/></h1>
                        <p className="cta-text"><LocalizedString stringName="waitlisted-p1"/></p>
                    </div>
                    }

                    {cta.mode === 'consentIncomplete' &&
                    <div className="cta">
                        <h1 className="cta-title"><LocalizedString stringName="please-complete-consent"/></h1>
                        <p className="cta-text"><LocalizedString stringName="complete-consent-p1"/></p>
                        <a className="cta-button-primary" onClick={() => startConsent()}><LocalizedString stringName="complete-consent"/></a>
                    </div>
                    }

                    {cta.mode === 'noTestingRequired' && 
                    <div className="cta">
                        { studyPhase === 'PreTesting' && 
                            <h1 className="cta-title"><LocalizedString stringName="welcome-h1"/></h1>
                        }

                        { studyPhase != 'PreTesting' && 
                            <div>
                                <h1 className="cta-title"><LocalizedString stringName="no-testing-required-h1"/></h1>
                                <p className="cta-text"><LocalizedString stringName="no-testing-required-acuteinfection-p1-v3"/>{6 - acuteInfectionDay}<LocalizedString stringName="days"/></p>
                            </div>
                        }

                        {(riskGroup === 'High' || (riskGroup === 'Low' && studyPhase === 'AcuteInfection'))  &&
                            <p className="cta-text"><LocalizedString stringName="no-testing-required-p2"/></p>
                        }

                        { hasSetupSymptomLog && studyPhase === 'PreTesting' && 
                            <p className="cta-text"><LocalizedString stringName="no-testing-required-pretesting-p1"/></p>
                        }

                        {tasks && tasks.length > 0 && 
                            <p className="cta-text"><LocalizedString stringName="no-testing-required-p3"/></p>
                        }
                        {hasIncompleteLuciraTask &&
                        <a className="cta-button-secondary" onClick={() => startLuciraTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-lucira-test"/></a>
                        }
                        {hasIncompletePcrTask &&
                        <a className="cta-button-secondary" onClick={() => startPcrTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-pcr-test"/></a>
                        }
                    </div>
                    }

                    {cta.mode === 'startFirstTest' &&
                    <div className="cta">
                        <h1 className="cta-title"><LocalizedString stringName="first-test-h1"/></h1>
                        <p className="cta-text"><LocalizedString stringName="first-test-p1-v3"/></p>
                        {tasks && tasks.length > 0 &&
                        <p className="cta-text"><LocalizedString stringName="first-test-p2"/></p>
                        }
                        <a className="cta-button-primary" onClick={() => recordResult()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="record-result-button"/></a>
                        {hasIncompleteLuciraTask &&
                        <a className="cta-button-secondary" onClick={() => startLuciraTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-lucira-test"/></a>
                        }
                        {hasIncompletePcrTask &&
                        <a className="cta-button-secondary" onClick={() => startPcrTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-pcr-test"/></a>
                        }
                    </div>
                    }

                    {cta.mode === 'startNextTest' &&
                    <div className="cta">
                        <h1 className="cta-title"><LocalizedString stringName="next-test-h1"/></h1>
                        <p className="cta-text"><LocalizedString stringName="next-test-p1"/></p>
                        <a className="cta-button-primary" onClick={() => recordResult()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="record-result-button"/></a>
                        {hasIncompleteLuciraTask &&
                        <a className="cta-button-secondary" onClick={() => startLuciraTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-lucira-test"/></a>
                        }                        
                        {hasIncompletePcrTask &&
                        <a className="cta-button-secondary" onClick={() => startPcrTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-pcr-test"/></a>
                        }
                    </div>
                    }

                    {cta.mode === 'viewResult' &&
                    <div className="cta">
                        <h1 className="cta-title"><LocalizedString stringName="test-complete-h1"/> <FontAwesomeIcon icon={faCheck} className="color-good"/></h1>
                        <p className="cta-text">
                            {!cta.confirmed &&
                            <LocalizedString stringName="test-result-status-p1"/>
                            }
                            {cta.confirmed &&
                            <LocalizedString stringName="test-result-status-p2"/>
                            }
                            &nbsp;
                            {cta.result === 'Negative' &&
                            <LocalizedString stringName="test-complete-negative" className="color-good"/>
                            }
                            {cta.result === 'Positive' &&
                            <LocalizedString stringName="test-complete-positive" className="color-warning"/>
                            }
                            {cta.result === 'Invalid' &&
                            <LocalizedString stringName="test-complete-invalid" className="color-caution"/>
                            }
                        </p>
                        {studyPhase === 'Testing' &&
                        <p className="cta-text"><LocalizedString stringName="test-complete-p2"/></p>
                        }                        
                        {hasIncompleteLuciraTask &&
                        <a className="cta-button-secondary" onClick={() => startLuciraTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-lucira-test"/></a>
                        }
                        {hasIncompletePcrTask &&
                        <a className="cta-button-secondary" onClick={() => startPcrTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="start-pcr-test"/></a>
                        }
                    </div>
                    }

                    {cta.mode === 'studyComplete' &&
                    <div className="cta">
                        <h1 className="cta-title"><LocalizedString stringName="study-complete-h1"/></h1>
                        <p className="cta-text"><LocalizedString stringName="study-complete-p1"/></p>
                    </div>
                    }

                    <div className="home-participant-identifier">
                        <LocalizedString stringName="participant-identifier"/> <strong>{participantInfo?.participantIdentifier}</strong>
                    </div>

                    {!studyComplete && consentStatus === 'Consented' &&
                    <div className="home-participant-identifier">
                        <LocalizedString stringName="study-week"/> <strong>{studyWeek} of 12</strong>
                    </div>
                    }
                </div>
            </div>
            <div className="home-body">
                <div>
                    {tasks && tasks.length > 0 &&
                    <div className="home-body-section">
                        <h2><LocalizedString stringName="surveys-h2"/></h2>
                        {tasks.map((task, i) =>
                            <div key={i} className="home-survey" onClick={() => startSurvey(task.surveyName)}>
                                <div>
                                    <div className="home-survey-title">{task.surveyDisplayName}</div>
                                    {task.surveyDescription &&
                                    <div className="home-survey-description"><FontAwesomeIcon icon={faClock}/> {task.surveyDescription}</div>
                                    }
                                </div>
                                <FontAwesomeIcon icon={faChevronRight} className="home-survey-nav"/>
                            </div>
                        )}
                    </div>
                    }

                    {consentStatus === 'Consented' && cta.mode !== 'studyComplete' && hasSetupSymptomLog &&
                    <div className="home-body-section">
                        <h2><LocalizedString stringName="new-risk-h2"/></h2>
                        <p><LocalizedString stringName="new-risk-p1"/></p>
                        <a className="home-action" onClick={() => logSymptomsAndExposure()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="new-risk-button"/></a>
                    </div>
                    }

                    {consentStatus === 'Consented' && cta.mode !== 'studyComplete' && cta.mode != 'startFirstTest' && cta.mode != 'startNextTest' &&
                    <div className="home-body-section">
                        <h2><LocalizedString stringName="new-test-h2"/></h2>
                        <p><LocalizedString stringName="new-test-p1"/></p>
                        <a className="home-action" onClick={() => startAntigenTest()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="new-test-button"/></a>
                    </div>
                    }

                    {testResults && testResults.find(r => r.result) &&
                    <div className="home-body-section">
                        <div className="home-test-header">
                            <h2><LocalizedString stringName="testing-journal-h2"/></h2>
                            <a className="home-test-view-all" onClick={() => showTestResults()}><LocalizedString stringName="testing-journal-all"/> <FontAwesomeIcon icon={faChevronRight}/></a>
                        </div>
                        <TestResultList participantInfo={participantInfo} maxResults={3}/>
                    </div>
                    }

                    {hasSetupSymptomLog &&
                    <div className="home-body-section">
                        <h2><LocalizedString stringName="manage-symptom-log-title"/></h2>
                        <Action indicatorIcon={faCog} title={language['edit-symptoms-title']} subtitle={symptomSharkParticipantInfo.symptoms.length + " " + language['active-symptoms-subtitle']} onClick={() => editSymptoms()}/>
                        <Action indicatorIcon={faCog} title={language['edit-treatments-title']} subtitle={symptomSharkParticipantInfo.treatments.length + " " + language['active-treatments-subtitle']} onClick={() => editTreatments()}/>
                    </div>
                    }

                    <div className="home-body-section">
                        <h2><LocalizedString stringName="study-hotline"/></h2>
                        <LocalizedString stringName="hotline-hours"/>
                        <h2 className="home-hotline-phone"><a target="_blank" href="tel:802-945-4827">802-945-4827</a></h2>
                        <LocalizedString stringName="off-hours-1a"/>
                        <h2><a target="_blank" href="mailto:hometesting@umassmed.edu">hometesting@umassmed.edu</a></h2>
                    </div>

                    {!connectedFitbitAccount && consentStatus === 'Consented' &&
                    <div className="home-body-section home-fitbit">
                        <h2><LocalizedString stringName="connect-fitbit-h2"/></h2>
                        <p><LocalizedString stringName="connect-fitbit-p1"/></p>
                        <a className="home-action" onClick={() => connectFitbit()}><FontAwesomeIcon icon={faPlusCircle}/> <LocalizedString stringName="connect-fitbit-button"/></a>
                    </div>
                    }

                    {cta.mode != 'consentIncomplete' && 
                    <div className="home-body-section">
                        <div onClick={() => onAchievementsClicked()}>
                            <div><h2><LocalizedString stringName="earn-rewards-h2"/> <FontAwesomeIcon icon={faChevronRight}/></h2></div>
                            <Achievementsv3 mode='compact'/>
                            <Achievementsv3_2 mode='compact'/>
                        </div>
                    </div>
                    }
                        

                    <div className="home-body-section">
                        <h2><LocalizedString stringName="update-address-h2"/></h2>
                        <p><LocalizedString stringName="update-address-p1-v3"/></p>
                        <a className="home-action" onClick={() => updateAddress()}><FontAwesomeIcon icon={faAddressCard}/> <LocalizedString stringName="update-address-button"/></a>
                    </div>

                    {showAppDownload &&
                    <div className="home-body-section">
                        <h2><LocalizedString stringName="download-app-h2"/></h2>
                        <div className="home-app-download">
                            <a target="_blank" href="https://apps.apple.com/us/app/mydatahelps/id1286789190"><img src="../images/download-apple.svg" alt="download apple"/></a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps"><img src="../images/download-android.svg" alt="download android"/></a>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className="home-footer">
                <div className="home-footer-credits"><LocalizedString stringName="footer-credits"/></div>
                <div className="home-footer-company"><LocalizedString stringName="footer-company"/></div>
            </div>
            <SymptomLogSync participantInfo={participantInfo}/>
        </div>
    )
}